@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fontsource/inter";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@layer utilities {
    .container {
        @apply px-[15px] mx-auto;
    }
    .header {
        @apply h-10;
        @apply flex items-center;
    }
    .footer {
        @apply h-10;
        @apply flex items-center;
    }
    .body {
        @apply h-screen;
    }
}
.slick-slide {
    margin-right: 15px;
}
